import React from 'react';
import { cn } from '@superside/ui';
import { Box } from '@konsus/superside-kit';
import { type SanityAsset } from '@konsus/image/src/types';
import { VisualAsset, MediaTypes } from '../../../components/VisualAsset';

export const ImageAuthor: React.FC<{
  image: SanityAsset;
  author: string;
  isMobile?: boolean;
  simplified?: boolean;
}> = ({ image, author, isMobile = false, simplified = false }) => {
  return image && author ? (
    <Box
      className={cn(
        '1024:h-[312px] 1024:w-[312px] 1920:h-96 1920:w-96 768:block 768:h-[260px] 768:w-[260px] relative hidden h-14 w-14 overflow-hidden rounded-lg [&_img]:object-cover [&_span]:!h-full [&_span]:!w-full',
        isMobile && '768:hidden block',
        simplified && 'block !h-14 !w-14'
      )}
    >
      <VisualAsset
        mediaType={MediaTypes.IMAGE}
        image={image}
        alt={author}
        layout='fill'
        requestWidth={624}
      />
    </Box>
  ) : null;
};

export default ImageAuthor;
