import React from 'react';
import { cn } from '@superside/ui';
import { Box, Text } from '@konsus/superside-kit';

export const Author: React.FC<{
  author: string;
  company: string;
  authorContextText: string;
  simplified?: boolean;
}> = ({ author, company, authorContextText, simplified = false }) => {
  return (
    <Box className={cn('768:flex-[initial] flex-1', simplified && '1440:flex-[initial] flex-1')}>
      {author ? (
        <Text
          className={cn(
            '1024:text-bor-lg 1920:text-[28px] text-base',
            simplified && '1024:!text-bor-lg !text-base'
          )}
          bold
        >
          {author}
        </Text>
      ) : null}
      {authorContextText ? (
        <Text
          className={cn(
            '768:[&:before]:hidden text-base font-normal',
            simplified && '768:!text-sm'
          )}
        >
          {authorContextText}
          {company ? `, ${company}` : ``}
        </Text>
      ) : null}
    </Box>
  );
};

export default Author;
