import React from 'react';
import { Box, cn } from '@superside/ui';
import QuotemarkSVG from './QuotemarkSVG';

const Quotemark: React.FC<{ isLight?: boolean; className: string }> = ({ isLight, className }) => (
  <Box
    className={cn(
      '1650:w-[290px] 768:w-52 text-bor-primary-100/5 dark:text-light/5 marble:text-bor-light/20 absolute',
      className,

      isLight && 'text-grey-100'
    )}
  >
    <QuotemarkSVG />
  </Box>
);

export const QuotemarksBackground: React.FC<{ isLight?: boolean }> = ({ isLight }) => {
  return (
    <Box>
      <Quotemark isLight={isLight} className='left-0 top-0 z-[-1] w-[156px]' />
      <Quotemark
        isLight={isLight}
        className='768:bottom-0 -bottom-6 left-auto right-0 top-auto z-[-1] w-[156px] rotate-180'
      />
    </Box>
  );
};

export default QuotemarksBackground;
