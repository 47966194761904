import React from 'react';
import { Box } from '@konsus/superside-kit';
import { Button } from '@superside/ui';
import { type QuotesContainerProps } from '../types';
import { SwipeableCards } from '../../../components';
import Quote from './Quote';

export const QuotesContainer: React.FC<QuotesContainerProps> = ({
  items,
  swiperIndex,
  isMultiple,
  fixedColumns,
  isLight,
  swiperContainerRef,
  switchQuote
}) => {
  const arrowShown = items.length > fixedColumns;
  const isSingle = items.length === 1 && !items[0].quote.author && !items[0].quote.image;

  return (
    <Box
      direction='row'
      align='center'
      justify='center'
      className={!isMultiple ? '1280:max-w-[1012px] 1650:max-w-[1336px]' : ''}
    >
      {arrowShown ? (
        <Button
          variant='iconGhost'
          onClick={() => switchQuote('left')}
          reverse
          className='<768:hidden hover:bg-bor-light/20'
        />
      ) : null}
      {items.length > 0 ? (
        <SwipeableCards
          items={items}
          renderItem={(item, idx) => {
            const twoActive = Math.abs(idx - swiperIndex) <= 1;
            const threeActive =
              (idx === swiperIndex && idx + 1 === swiperIndex + 1 && idx + 2 === swiperIndex + 2) ||
              (idx - 1 === swiperIndex && idx === swiperIndex + 1 && idx + 1 === swiperIndex + 2) ||
              (idx - 2 === swiperIndex && idx - 1 === swiperIndex + 1 && idx === swiperIndex + 2);

            const multipleActive = fixedColumns === 2 ? twoActive : threeActive;
            const isActive = isMultiple ? multipleActive : idx === swiperIndex;

            return (
              <Quote
                {...item.quote}
                active={isActive}
                simplified={isMultiple}
                isLight={isLight}
                sliderEnabled={arrowShown}
                isSingle={isSingle}
              />
            );
          }}
          swiperContainerRef={swiperContainerRef}
        />
      ) : null}
      {arrowShown ? (
        <Button
          variant='iconGhost'
          onClick={() => switchQuote('right')}
          className='<768:hidden hover:bg-bor-light/20'
        />
      ) : null}
    </Box>
  );
};
