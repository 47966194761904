import React from 'react';

const QuotemarkSVG: React.FC = () => (
  <svg viewBox='0 0 290 230' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M105.046 0L85.2941 109.183H121.207V230H0V107.393L32.322 0H105.046ZM273.839 0L254.087 109.183H290V230H168.793V107.393L201.115 0H273.839Z'
      fill='currentColor'
    />
  </svg>
);

export default QuotemarkSVG;
