import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper';
import { Keyboard, Mousewheel, Autoplay } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { type Swiper as SwiperType } from 'swiper/types';

type GlobalDirection = 'right' | 'left';

export type SwipeableHookOptions = {
  hasMultipleItems?: boolean;
  fixedColumns?: number;
  intervalTime?: number;
  isMultiple?: boolean;
  spaceBetween?: number;
};

export const useSwipeable = ({
  hasMultipleItems = true,
  fixedColumns = 1,
  intervalTime,
  isMultiple = false,
  spaceBetween = 40
}: SwipeableHookOptions) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const swiperContainerRef = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(0);

  const switchQuote = (direction: GlobalDirection) => {
    if (direction === 'right') {
      swiperRef?.current?.slideNext();
    } else if (direction === 'left') {
      swiperRef?.current?.slidePrev();
    }
  };

  useEffect(() => {
    if (hasMultipleItems) {
      setIndex(0);
      setTimeout(() => {
        swiperRef?.current?.destroy(true, true);
        swiperRef.current = new Swiper(swiperContainerRef.current as HTMLDivElement, {
          modules: [Keyboard, Mousewheel, Autoplay],
          loop: true,
          slidesPerView: fixedColumns,
          slidesPerGroup: fixedColumns,
          initialSlide: 0,
          speed: 700,
          spaceBetween: isMultiple ? spaceBetween : 24,
          grabCursor: true,
          mousewheel: {
            thresholdDelta: 20,
            forceToAxis: true,
            sensitivity: 0.5
          },
          autoplay: intervalTime
            ? {
                delay: intervalTime,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
              }
            : false
        });

        swiperRef?.current.on('realIndexChange', (swiper) => {
          setIndex(swiper?.realIndex);
        });

        swiperContainerRef?.current?.addEventListener('mouseleave', () => {
          swiperRef?.current?.autoplay.resume();
        });

        return () => {
          swiperRef?.current?.destroy(true, true);
        };
      }, 100);
    }
  }, [fixedColumns, intervalTime, isMultiple, hasMultipleItems, spaceBetween]);

  return { swiperRef, swiperContainerRef, switchQuote, index };
};

export interface SwipeableCardsProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  swiperContainerRef?: React.RefObject<HTMLDivElement>;
  swiperSlideClassName?: string;
}

export const SwipeableCards = <T,>({
  items,
  renderItem,
  swiperContainerRef: swiperRef,
  swiperSlideClassName
}: SwipeableCardsProps<T>) => {
  return (
    <div ref={swiperRef} className='swiper-container no-scrollbar'>
      <div className='swiper-wrapper'>
        {items.map((item, index) => (
          <SwiperSlide className={swiperSlideClassName} key={index}>
            {renderItem(item, index)}
          </SwiperSlide>
        ))}
      </div>
    </div>
  );
};

export default SwipeableCards;
