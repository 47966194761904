import React, { useContext, useEffect, useState, useCallback } from 'react';
import { cn, Heading } from '@superside/ui';
import { Box, useScreenSize } from '@konsus/superside-kit';
import { PageSection } from '../../components/PageSection';
import GlobalContext from '../../contexts/GlobalContext';
import { PaginationDotsAnimated, useSwipeable } from '../../components';
import { type QuotesProps } from './types';
import { QuotesContainer } from './components/QuotesContainer';
import { SWITCH_QUOTES_INTERVAL } from './utils';

export const Quotes: React.FC<QuotesProps> = ({
  quotes,
  columns = 1,
  headerText,
  isLight,
  intervalTime = SWITCH_QUOTES_INTERVAL,
  paddingTop,
  paddingBottom
}) => {
  const { quotesData } = useContext(GlobalContext) || {};
  const rawItems = quotes || quotesData || [];

  const { breakpoints } = useScreenSize();

  const [items, setItems] = useState(rawItems);
  const [isMultiple, setMultiple] = useState(columns !== 1);
  const [fixedColumns, setFixedColumns] = useState(columns);
  const [unlimitedQuotesContainer, setUnlimitedQuotesContainer] = useState(false);
  const [spaceBetween, setSpaceBetween] = useState(40);

  const { swiperRef, swiperContainerRef, switchQuote, index } = useSwipeable({
    hasMultipleItems: items.length > 1,
    fixedColumns,
    intervalTime,
    isMultiple,
    spaceBetween
  });

  const fixIndex = useCallback(
    (index: number) => {
      let fixedIndex = index;
      const initialLengthOfItems = rawItems.length;

      if (index + 1 > initialLengthOfItems) {
        const diff = Math.max(fixedColumns - 1, 1);
        const multiplier =
          Math.ceil(index / initialLengthOfItems - 1) || index / initialLengthOfItems;

        fixedIndex = index - initialLengthOfItems * diff * multiplier;
      }

      if (fixedIndex % fixedColumns !== 0) {
        fixedIndex--;
      }

      return fixedIndex;
    },
    [fixedColumns, rawItems.length]
  );

  useEffect(() => {
    if (fixedColumns !== 1 && rawItems.length % fixedColumns !== 0) {
      let fixedItems = [...rawItems];

      for (let i = 0; i < fixedColumns - 1; i++) {
        fixedItems = [...fixedItems, ...rawItems];
      }

      setItems(fixedItems);
    }
  }, [fixedColumns, rawItems]);

  useEffect(() => {
    setSpaceBetween(breakpoints?.xxlarge ? 74 : breakpoints?.desktop ? 24 : 40);

    setFixedColumns(() => {
      if (!breakpoints.medium) {
        return 1;
      } else if (!breakpoints.xmlarge && columns === 3 && items.length >= 3) {
        return 2;
      } else if (items.length >= columns) {
        return columns;
      } else {
        return 1;
      }
    });
  }, [breakpoints, columns, items.length]);

  useEffect(() => {
    setMultiple(fixedColumns !== 1);
  }, [fixedColumns]);

  useEffect(() => {
    setUnlimitedQuotesContainer(!!(isMultiple && breakpoints.xmlarge));
  }, [breakpoints.xmlarge, isMultiple]);

  return !items ? null : (
    <PageSection
      isLight={isLight}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      style={{ overflow: 'hidden' }}
      unlimited={unlimitedQuotesContainer}
    >
      <Box
        align='center'
        className={cn(
          items.length > 1 && '[&_.swiper-wrapper]:!w-screen',
          '1650:max-w-[1555px] mx-auto my-0 [&_.swiper-container]:w-full [&_.swiper-container]:overflow-hidden',
          items.length === 1 && '[&_.swiper-container]:overflow-auto'
        )}
      >
        {headerText ? (
          <Heading
            className='m-w-[656px] 768:mb-14 1024:mb-15 1650:mb-20 mb-10 text-center'
            level='h3'
            as='h2'
          >
            {headerText}
          </Heading>
        ) : null}

        {items ? (
          <QuotesContainer
            items={items}
            switchQuote={switchQuote}
            swiperIndex={index}
            isMultiple={isMultiple}
            fixedColumns={fixedColumns}
            isLight={isLight}
            swiperContainerRef={swiperContainerRef}
          />
        ) : null}

        {items.length > fixedColumns ? (
          <div className='row 768:mt-14 1024:mt-15 1650:mt-20 mt-10 flex'>
            <PaginationDotsAnimated
              index={Math.ceil(fixIndex(index) / fixedColumns)}
              length={Math.ceil(rawItems.length / fixedColumns)}
              isLight={isLight}
              changeIndex={(i) => {
                swiperRef.current?.slideToLoop(isMultiple ? Math.ceil(i * fixedColumns) : i);
              }}
              className={cn(isMultiple && '1440:hidden flex')}
            />
          </div>
        ) : null}
      </Box>
    </PageSection>
  );
};

export default Quotes;
