import { type QuoteRichText } from '../types';

const truncateString = (str: string, position: number) => str.split('', position).join('') + '..';

export const truncateRichTextArray = (richtext: QuoteRichText[], limit: number) => {
  if (!richtext) {
    return null;
  }

  let charactersLength = 0;

  const result = richtext
    ?.map((block) => {
      block.children = block.children
        ?.map((child) => {
          const truncatePosition = limit - charactersLength;

          if (charactersLength < limit) {
            if (charactersLength + child.text.length > limit) {
              child.text = truncateString(child.text, truncatePosition);
            } else {
              charactersLength += child.text.length;
            }

            return child;
          } else {
            if (limit - charactersLength > 0) {
              child.text = truncateString(child.text, truncatePosition);

              return child;
            } else {
              return null;
            }
          }
        })
        .filter(Boolean);

      return block;
    })
    .filter(Boolean);

  return result;
};
