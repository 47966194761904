import React, { useEffect, useState } from 'react';
import { cn } from '@superside/ui';
import {
  Box,
  Text,
  ExternalContentWrapper,
  contentWrapperStyle,
  RelativeBox,
  useScreenSize
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { VisualAsset, MediaTypes } from '../../../components/VisualAsset';
import { QUOTE_TEXT_CHARACTERS_LIMIT, truncateRichTextArray } from '../utils';
import { type QuoteRichText, type QuoteProps } from '../types';
import Author from './Author';
import ImageAuthor from './ImageAuthor';
import QuotemarksBackground from './QuotemarksBackground';
import styles from './styles.module.css';

export const Quote: React.FC<QuoteProps> = ({
  quoteText,
  quoteRichText,
  image,
  author,
  company,
  authorContextText,
  companyLogo,
  active,
  simplified,
  isLight,
  sliderEnabled,
  isSingle
}) => {
  const { breakpoints } = useScreenSize();

  const [truncatedQuoteRichText, setTruncatedQuoteRichText] = useState<QuoteRichText[] | null>(
    null
  );
  const [delayedActive, setDelayedActive] = useState(active);
  const [activeText, setActiveText] = useState(false);
  const [mobileQuotemark, setMobileQuotemark] = useState(true);

  useEffect(() => {
    setMobileQuotemark(!!breakpoints?.medium);
  }, [breakpoints]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setActiveText(delayedActive);

      return () => clearTimeout(timeoutId);
    }, 450);
  }, [delayedActive]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayedActive(active);

      return () => clearTimeout(timeoutId);
    }, 300);
  }, [active]);

  useEffect(() => {
    if (quoteRichText) {
      setTruncatedQuoteRichText(truncateRichTextArray(quoteRichText, QUOTE_TEXT_CHARACTERS_LIMIT));
    }
  }, [quoteRichText]);

  return (
    <Box
      className={cn(
        !simplified &&
          '1024:gap-10 768:flex-row 768:gap-4 relative w-auto select-none flex-col justify-center transition-all duration-[0.6s] ease-[ease-in-out]',
        simplified &&
          '1024:gap-10 768:flex-col 768:gap-8 mr-32 w-full flex-col-reverse gap-6 opacity-0 transition-all duration-[0.1s] ease-[ease-in-out]',
        simplified && !sliderEnabled && 'opacity-100',
        simplified && sliderEnabled && delayedActive && !isSingle && 'animate-fadeInUp'
      )}
      style={
        !simplified
          ? {
              transform: delayedActive ? 'scale(1)' : 'scale(0.75)',
              opacity: delayedActive ? '1' : '0.5'
            }
          : {}
      }
    >
      {image || author || authorContextText || company ? (
        <Box className={cn(simplified && '768:gap-6 w-full shrink gap-4')} direction='row'>
          <ImageAuthor image={image} author={author} simplified={simplified} />

          {simplified ? (
            <Author
              simplified
              author={author}
              authorContextText={authorContextText}
              company={company}
            />
          ) : null}
        </Box>
      ) : null}
      <RelativeBox
        className={cn(
          !simplified &&
            '1280:pl-6 1280:w-[448px] 1650:w-[642px] 768:gap-0 768:order-2 768:pl-4 768:w-[416px] w-full gap-6 bg-contain bg-[100%_0] bg-no-repeat',
          isSingle && '1024:!w-[757px] 1024:pl-0 1650:!w-[1064px] 768:!w-[720px] 768:pl-0'
        )}
      >
        {!simplified && mobileQuotemark && !isSingle ? (
          <QuotemarksBackground isLight={isLight} />
        ) : null}

        <RelativeBox
          className={cn(
            simplified && '1280:[&_span,p]:text-bor-lg w-full font-semibold [&_span,p]:text-base',
            !simplified && styles.richTextWrap,
            !simplified &&
              '1024:max-h-[195px] 1650:max-h-[226px] 1920:max-h-64 768:max-h-[166px] marble:font-normal mx-0 my-auto h-fit min-h-[216px] font-semibold opacity-0 transition-all duration-[0.35s] ease-[ease-in-out] [&_span,p]:text-base',
            !simplified && activeText && 'animate-fadeInUp'
          )}
          justify='center'
        >
          {!simplified && !mobileQuotemark && !isSingle ? (
            <QuotemarksBackground isLight={isLight} />
          ) : null}

          {truncatedQuoteRichText ? (
            <ExternalContentWrapper
              className={cn(
                contentWrapperStyle,
                isSingle &&
                  '1920:!text-[28px] 1920:[&_p]:!text-[28px] 768:!text-2xl 768:[&_p]:!text-2xl text-center !text-xl font-normal [&_p]:text-center [&_p]:!text-xl [&_p]:font-normal [&_a:hover]:underline [&_a]:no-underline'
              )}
            >
              <SanityBlockComponent blocks={truncatedQuoteRichText} />
            </ExternalContentWrapper>
          ) : quoteText ? (
            <Text
              className={cn(
                isSingle &&
                  '1920:!text-[28px] 1920:[&_p]:!text-[28px] 768:!text-2xl 768:[&_p]:!text-2xl text-center !text-xl font-normal [&_p]:text-center [&_p]:!text-xl [&_p]:font-normal'
              )}
            >
              {quoteText}
            </Text>
          ) : null}
        </RelativeBox>
        <Box
          className={!simplified ? '1650:gap-10 768:gap-6 shrink items-center gap-4' : ''}
          direction='row'
        >
          {!simplified ? (
            <>
              <ImageAuthor image={image} author={author} isMobile />
              <Author author={author} authorContextText={authorContextText} company={company} />
            </>
          ) : null}

          {companyLogo ? (
            <Box
              className={cn(
                '768:flex w-30 marble:brightness-0 relative hidden [&_img]:w-full [&_img]:object-contain [&_img]:object-left',
                simplified && 'mt-10 h-8'
              )}
              justify='center'
            >
              <VisualAsset
                mediaType={MediaTypes.IMAGE}
                image={companyLogo}
                alt={company}
                className='768:!h-12 1024:!h-14 1920:!h-16'
              />
            </Box>
          ) : null}
        </Box>
      </RelativeBox>
    </Box>
  );
};

export default Quote;
